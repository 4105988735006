import { createAsyncAction, createAction } from "typesafe-actions"

// Models
import {
    SignInCredentialsInterface,
    SignUpRequestDto,
    UserEditDto,
    UserInterface
} from "../../models"

// Helpers
import { errorPayload } from "../../../../store/helpers"

// Token
export const setToken = createAction("@@auth/SET_TOKEN", (token: string) => ({ token }))();

// Sign in
export const signIn = createAction("@@auth/SIGN_IN", (credentials: SignInCredentialsInterface) => ({ credentials }))();
export const signInSuccess = createAction("@@auth/SIGN_IN_SUCCESS", (user: UserInterface) => ({ user }))();
export const signInFailure = createAction("@@auth/SIGN_IN_FAILURE", errorPayload)();
export const demoSignIn = createAction("@@auth/DEMO_SIGN_IN", (credentials: SignInCredentialsInterface) => ({ credentials }))();

// Sign up
export const signUp = createAction("@@auth/SIGN_UP", (requestDto: SignUpRequestDto) => ({ requestDto }))();
export const signUpSuccess = createAction("@@auth/SIGN_IN_SUCCESS")();
export const signUpFailure = createAction("@@auth/SIGN_IN_FAILURE", errorPayload)();

// User
export const fetchCurrentUser = createAction("@@auth/FETCH_CURRENT_USER")();
export const fetchCurrentUserSuccess = createAction("@@auth/FETCH_CURRENT_USER_SUCCESS", (user: UserInterface) => ({ user }))();
export const fetchCurrentUserFailure = createAction("@@auth/FETCH_CURRENT_USER_FAILURE", errorPayload)();

// Sign out
export const signOut = createAction("@@auth/SIGN_OUT")();
export const signOutSuccess = createAction("@@auth/SIGN_OUT_SUCCESS")();
export const signOutFailure = createAction("@@auth/SIGN_OUT_FAILURE", errorPayload)();

// Reset password
export const resetPassword = createAction("@@auth/RESET_PASSWORD", (email: string) => ({ email }))();
export const resetPasswordSuccess = createAction("@@auth/RESET_PASSWORD_SUCCESS")();
export const resetPasswordFailure = createAction("@@auth/RESET_PASSWORD_FAILURE", errorPayload)();

// Change password
export const changePassword = createAction("@@auth/CHANGE_PASSWORD", (token: string, password: string) => ({ token, password }))();
export const changePasswordSuccess = createAction("@@auth/CHANGE_PASSWORD_SUCCESS")();
export const changePasswordFailure = createAction("@@auth/CHANGE_PASSWORD_FAILURE", errorPayload)();
export const changeUserPassword = createAsyncAction(
    "@@auth/CHANGE_USER_PASSWORD",
    "@@auth/CHANGE_USER_PASSWORD_SUCCESS",
    "@@auth/CHANGE_USER_PASSWORD_FAILURE"
)<string, [undefined, undefined], unknown>()

// Confirm account
export const confirmAccount = createAction("@@auth/CONFIRM_ACCOUNT", (token: string) => ({ token }))();
export const confirmAccountSuccess = createAction("@@auth/CONFIRM_ACCOUNT_SUCCESS")();
export const confirmAccountFailure = createAction("@@auth/CONFIRM_ACCOUNT_FAILURE", errorPayload)();

// Re-send confirmation
export const resendConfirmation = createAction("@@auth/RESEND_CONFIRMATION", (email: string) => ({ email }))();
export const resendConfirmationSuccess = createAction("@@auth/RESEND_CONFIRMATION_SUCCESS")();
export const resendConfirmationFailure = createAction("@@auth/RESEND_CONFIRMATION_FAILURE", errorPayload)();

// Errors from server
export const clearServerError = createAction("@@auth/CLEAR_SERVER_ERROR")();

// Edit current user
export const editUser = createAsyncAction(
    "@@auth/EDIT_USER",
    "@@auth/EDIT_USER_SUCCESS",
    "@@auth/EDIT_USER_FAILURE"
)<UserEditDto, UserInterface, unknown>()
