export const AUTH_COOKIE_KEY = "authorization";

export const AuthEndpoint = {
    USER: "auth/user/current",
    EDIT_USER: "auth/user/{{id}}",
    SIGN_IN: "auth/oauth/token",
    SIGN_UP: "auth/user",
    CONFIRM: "auth/user/confirm",
    RESEND_CONFIRMATION: "auth/user/confirm/resend",
    PASSWORD_RESET: "auth/user/password/reset",
    PASSWORD_CHANGE: "auth/user/password/change",
};

export const AuthRequestProperty = {
    USERNAME: "username",
    PASSWORD: "password",
    GRANT_TYPE: "grant_type",
    REFRESH_TOKEN: "refresh_token",
};
