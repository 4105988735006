import {AUTH_COOKIE_KEY} from "../../modules/authorization/constants"
import TokenStorageServiceType from "./TokenStorageServiceType"

const TokenStorageService: TokenStorageServiceType = Object.create(null, {
    token: {
        enumerable: true,
        configurable: false,
        writable: true,
        value: null,
    },
});

TokenStorageService.setToken = function setToken(token: string | null) {
    this.token = token;
};

TokenStorageService.getFromStorage = function getFromStorage() {
    if (typeof window !== "undefined") {
        const cookies = document.cookie.split(";");
        const authCookie = cookies.find(c => c.indexOf(AUTH_COOKIE_KEY) !== -1);

        if (typeof authCookie === "undefined") {
            return
        }

        const [, token] = authCookie.split("=");

        this.token = token;
    } else {
        // TODO: mobile
    }
};

TokenStorageService.saveToStorage = function saveToStorage(token: string, expiresIn: number) {
    if (typeof window !== "undefined") {
        document.cookie = `${AUTH_COOKIE_KEY}=${token};path=/;max-age=${expiresIn}`;
        this.token = token;
    }
};

TokenStorageService.getToken = function getToken() {
    return this.token
};

TokenStorageService.clear = function clear() {
    this.token = null;

    if (typeof window !== "undefined") {
        document.cookie = `${AUTH_COOKIE_KEY}=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }

    // TODO: mobile
};

export default TokenStorageService
