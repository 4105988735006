import React from "react"

// Models
import {ReduxNextContext} from "./withRedux"
import UtilsInterface from "../services/Utils/UtilsInterface";

// Utils
import Utils from "../services/Utils/Utils"

export interface WithUtilsProps {
    utils: UtilsInterface
}

export default function withUtils(BaseComponent: any): any {
    WithUtils.getInitialProps = async function (context: ReduxNextContext) {
        if (typeof BaseComponent.getInitialProps !== "undefined") {
            return await BaseComponent.getInitialProps(context)
        }

        return {}
    }

    function WithUtils(props: any) {
        return <BaseComponent {...props} utils={Utils}/>
    }

    return WithUtils
}
